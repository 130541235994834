import React, { Component } from 'react'
import Layout from '../layouts/Layout'
import '../styles/scss/about.scss'
import InteractiveImages from './Common/InteractiveImages'
import Titles from './Common/Titles'
import axios from 'axios'

export default class About extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: {
                name: 'about-us',
                title: {
                    text: "About"
                },
                subtitle: {
                    text: "us"
                },
                text: [
                    {
                        paragraph: "Verse Estate is a VR technology company headquartered in Dubai providing metaverse solutions (consumer metaverse/ enterprise metaverse/industrial metaverse). Unlike gaming metaverses, Verse Estate is focused on hyper realistic designs."
                    },
                    {
                        paragraph: "Verse Estate is currently building a full Virtual Reality World (“Verse World”), with its own marketplace and virtual economy. Our high-tech 6000 sq. ft. showroom at the heart of Dubai in AlSerkal Avenue is open to visitors.The showroom of Verse Estate is equipped with the biggest 3D scanning studio in the world, VR Table, VR Technology to try out, and a racing simulator.Feel free to visit us!"
                    }
                ],
                images: [
                    {
                        source: '',
                        dimensions: {
                            width: '40%',
                            height: '250px'
                        }
                    },
                    {
                        source: '',
                        dimensions: {
                            width: '40%',
                            height: '350px'
                        }
                    },
                    {
                        source: '',
                        dimensions: {
                            width: '100%',
                            height: '150px'
                        }
                    },
                ]
            }
        }
    }

    componentDidMount = () => {
        this.updatePageData()
    }

    updatePageData = () => {
        axios.get(`https://versemail.azurewebsites.net/page/${this.state.page?.name}`).then(response => {
            if (response?.data) {
                this.setState({
                    page: {
                        ...this.state.page,
                        ...response?.data
                    }
                })
            }
        }).catch((error) => {
            console.log(error.response)
        });
    }

    renderContentText = () => {
        return this.state.page?.text.map((element, index) => {
            return (
                <p key={index}>
                    {element?.paragraph}
                </p>
            )
        })
    }

    renderImages = () => {
        return <InteractiveImages />
    }

    render() {
        return (
            <div id='about'>
                <Titles
                    title={this.state.page?.title?.text}
                    subtitle={this.state.page?.subtitle?.text}
                />
                <section className='no-padding-top'>
                    <div className='content-container row'>
                        {this.renderImages()}
                        <div className='content m-auto'>
                            {this.renderContentText()}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
