import * as React from "react"
import { Link, navigate } from "gatsby"

import '../styles/fonts.css'
import Layout from "../layouts/Layout"
import ARMRVR from '../components/ARMRVR'
import About from "../components/About"
import Modeling3D from '../components/Modeling3D'
import Scaning from '../components/Scaning'
import LivePerformance from '../components/LivePerformance'
import EventsPhysical from '../components/EventsPhysical'
import EventsVirtual from '../components/EventsVirtual'
import Motion from '../components/Motion'
import Games from '../components/Games'
import Join from '../components/Join'
import Hero from '../components/3D/Hero'

const NotFoundPage = () => {
  return (
    <Layout>
      <Hero />
      <ARMRVR />
      <Motion />
      <Games />
      <Modeling3D />
      <Scaning />
      <EventsPhysical />
      <EventsVirtual />
      <LivePerformance />
      <About />
      <Join />
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
