import React, { Component } from 'react'
import Titles from './Common/Titles'
import '../styles/scss/scrollGallery.scss'
import '../styles/scss/events.scss'
import ScrollGallery from './Common/ScrollGallery'
import Modal from './Common/Modal'

export default class Events extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        page: null,
        legend:[
            {
                "name":"Passed",
                "color":"#FF5F5F"
            },
            {
                "name":"Still available",
                "color":"#00D600"
            },
            {
                "name":"Booked",
                "color":"#5040A1"
            },
        ]
      }
    }

    componentDidMount = () => {
        // temporary
        this.setState({
            page: this.props.page
        }, () => {
            if (this.state.page?.gallery?.length)
                this.temporarySeed(this.state.page?.gallery[0]?.event)
        })
    }

    /**
     * We temporarly seed the data just for the design
     * @param {*} event 
     */
    temporarySeed = (event) => {
        let gallery = this.state.page?.gallery.map(element => {
            element.event = {
                ...event,
                status: element?.event?.status // we keep the existing status
            }

            return element;
        })

        this.setState({
            page: {
                ...this.state.page,
                gallery: gallery
            }
        })
    }

    selectElement = (element) => {
        this.setState({
            selectedElement: element
        })
    }

    renderModal = () => {
        if (this.state.selectedElement)
            return <Modal
                selectedElement={this.state.selectedElement}
                closeModal={this.selectElement}
            />
    }

    renderLegend = () => {
        return(
            <div className='legend-container'>
                {
                    this.state.legend.map((legend) => {
                        return(
                            <div className='legend-item'>
                                <span style={{ background: legend.color }}></span>
                                <span>{legend.name}</span>
                            </div>
                        );  
                    })
                }
            </div>
        )
    }
    createEvent = () => {
        return(
            <div className='create-event'>
                Create your event
            </div>
        )
    }

    render() {
        return (
            <div className='events-page' id={this.state.page?.name}>
                <div className='top'>
                    <Titles
                        title={this.state.page?.title?.text}
                        subtitle={this.state.page?.subtitle?.text}
                    />
                    {this.createEvent()}
                    {this.renderLegend()}
                </div>
                

                <ScrollGallery
                    gallery={this.state.page?.gallery}
                    selectElement={this.selectElement}
                />

                {this.renderModal()}
            </div>
        )
    }
}
