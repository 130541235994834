import React, { Component } from 'react'
import Events from './Events'

export default class EventsPhysical extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: {
                name: 'events-physical',
                title: {
                    text: 'Events',
                },
                subtitle: {
                    text: 'in the physical world',
                },
                gallery: [
                    {
                        source: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1677597825/410bb3323944c7db229261e42ca6592c_pyjz4z.jpg',
                        title: 'Lorem Ipsum',
                        content: `
                        Lorem ipsum dolor sit amet consectetur.  ipsum dolor sit amet ectetur.  ipsum dolor Lorem ipsum dolor sit aconsectetur. Mi nunc amet Lorem ipsum dolor sit amet consectetur. Mi nunc amet Lorem ipsum dolor sit amet consectetur. Mi nunc amet Lorem ipsum dolor sit amet consectetur. Mi nunc amet Lorem ipsum dolor sit amet consectetur. Mi nunc amet Lorem ipsum dolor sit amet consectetur. Mi nunc amet Lorem ipsum dolor sit amet consectetur.
                        `,
                        event: {
                            status: 'passed',
                            date: '25 Feb 2023',
                            time: '7:30 pm GMT+5',
                            duration: '60 min',
                            link: 'https://www.eventbrite.com/myevent?eid=59693681',
                            organiser: {
                                name: 'SoulCurryArt',
                                handle: '@soulcurryart',
                                info: `Modern Cubist abstract artist, NFT artist, Art educator & bridging the Contemporary with the Digital art realms

                                Modern Cubist abstract artist, NFT artist, Art educator & bridging the Contemporary with the Digital art realms
                                `,
                                image: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1670793919/bmzvpfh7ju6kklifl5yf.jpg',
                                socials: [
                                    {
                                        type: 'facebook',
                                        url: ''
                                    },
                                    {
                                        type: 'twitter',
                                        url: ''
                                    },
                                    {
                                        type: 'linkedin',
                                        url: ''
                                    }
                                ]
                            }
                        }
                    },
                    {
                        source: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1677597818/87478beb00799f2fcddc475f04a781a4_wwazy6.png',
                        title: 'Lorem Ipsum',
                        
                        content: `
                        Lorem ipsum dolor sit amet consectetur.  ipsum dolor sit amet ectetur.  ipsum dolor  ...
                        `,
                        event: {
                            status: 'available',
                            link: 'https://www.eventbrite.com/myevent?eid=59693681',
                        }
                    },
                    {
                        source: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1677597822/7542923cc95127d61b26bc931d5036b7_urb2rf.jpg',
                        title: 'Lorem Ipsum',
                        content: `
                        Lorem ipsum dolor sit amet consectetur.  ipsum dolor sit amet ectetur.  ipsum dolor  ...
                        `,
                        event: {
                            status: 'available',
                        }
                    },
                    {
                        source: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1677597825/d588c34b6e3cd4aa51169c80ee2c3042_s4p2ki.jpg',
                        title: 'Lorem Ipsum',
                        content: `
                        Lorem ipsum dolor sit amet consectetur.  ipsum dolor sit amet ectetur.  ipsum dolor  ...
                        `,
                        event: {
                            status: 'passed',
                        }
                    },
                    {
                        source: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1677597823/4ea4af418bcd041e0afada42be15109e_ojz8j7.jpg',
                        title: 'Lorem Ipsum',
                        content: `
                        Lorem ipsum dolor sit amet consectetur.  ipsum dolor sit amet ectetur.  ipsum dolor  ...
                        `,
                        event: {
                            status: 'booked',
                        }
                    },
                    {
                        source: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1677597820/61e03795c726fb26340029225edcd01f_hkp06m.jpg',
                        title: 'Lorem Ipsum',
                        content: `
                        Lorem ipsum dolor sit amet consectetur.  ipsum dolor sit amet ectetur.  ipsum dolor  ...
                        `,
                        event: {
                            status: 'available',
                        }
                    },
                    {
                        source: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1677597825/410bb3323944c7db229261e42ca6592c_pyjz4z.jpg',
                        title: 'Lorem Ipsum',
                        content: `
                        Lorem ipsum dolor sit amet consectetur.  ipsum dolor sit amet ectetur.  ipsum dolor 
                        `,
                        event: {
                            status: 'passed',
                        }
                    },
                    {
                        source: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1677597818/87478beb00799f2fcddc475f04a781a4_wwazy6.png',
                        title: 'Lorem Ipsum',
                        content: `
                        Lorem ipsum dolor sit amet consectetur.  ipsum dolor sit amet ectetur.  ipsum dolor  ...
                        `,
                        event: {
                            status: 'available',
                        }
                    },
                    {
                        source: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1677597822/7542923cc95127d61b26bc931d5036b7_urb2rf.jpg',
                        title: 'Lorem Ipsum',
                        content: `
                        Lorem ipsum dolor sit amet consectetur.  ipsum dolor sit amet ectetur.  ipsum dolor  ...
                        `,
                        event: {
                            status: 'available',
                        }
                    }
                ]
            }
        }
    }

    render() {
        return <Events
            page={this.state.page}
        />
    }
}
