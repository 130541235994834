import React, { Component } from 'react'
import Events from './Events'

export default class EventsVirtual extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: {
                name: 'events-physical',
                title: {
                    text: 'Events',
                },
                subtitle: {
                    text: 'in the virtual world',
                },
            }
        }
    }
    
    render() {
        return <Events
            page={this.state.page}
        />
    }
}
